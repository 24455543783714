export default {
	namespaced: true,
	state: () => ({
		// rpcUrl: [
		// 	"https://swr.xnftdata.com/rpc-proxy/",
		// 	"https://swift-mariele-fast-mainnet.helius-rpc.com/",
		// 	"https://explorer-api.mainnet-beta.solana.com/"
		// ].sort(() => 0.5 - Math.random())[0]
		rpcUrl: "https://swift-mariele-fast-mainnet.helius-rpc.com/"
	}),
	mutations: {
		//
	}
}
