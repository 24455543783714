<template>
	<div id="app">
		<div id="content-wrap">
			<Navbar></Navbar>
			<router-view class="view mb-2"></router-view>
			<portal-target name="body"></portal-target>
		</div>
		<Footer id="footer"></Footer>
	</div>
</template>

<script>

import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
	name: 'App',
	components: {Navbar, Footer},
	methods: {
		getTokens: async function () {
			const resp = await (await fetch("https://token.jup.ag/strict")).json()
			this.$store.commit("cache/set_token_map", resp)
		},
		checkOnline: async function () {
			const resp = (await fetch("https://api.rugcheck.xyz/v1/maintenance"))
			if (!resp || (resp.status !== 200 && resp.status !== 404)) {
				this.$router.push("/maintenance")
			}
		}
	},
	beforeMount() {
		this.$store.commit("solana/set_endpoint", this.$store.state.rpc.rpcUrl)
		this.$store.dispatch('accounts/fetch_known_accounts');

		if (this.$route.query.key) {
			this.$store.commit("wallet/set_jwt", this.$route.query.key)
		}


		this.checkOnline()
		this.getTokens()
	},
}
</script>

<style>
#app{
	position: relative;
	min-height: 100vh;
}

#content-wrap {
	padding-bottom: 1.2rem;    /* Footer height */
}

#footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/*height: 1.5rem;*/
	/*min-height: 1.5rem;            !* Footer height *!*/
}
</style>
